var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "transform-scale-7" },
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6 mt-16", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pt-16 mt-16" },
            [
              _c(
                "v-col",
                { staticClass: "ms-14 text-center", attrs: { md: "1" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-avatar",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "border-radius-lg mx-auto mb-3 d-block",
                                      attrs: { width: "48px", rounded: "" },
                                    },
                                    "v-avatar",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("img", {
                                    staticClass: "border-radius-lg",
                                    attrs: {
                                      src: require("@/assets/img/team-1.jpg"),
                                      alt: "Avatar",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("My profile")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "mt-2 px-2 py-2 mx-auto shadow shadow-sm border-radius-lg d-block",
                                      attrs: {
                                        "min-width": "45",
                                        ripple: false,
                                        height: "45",
                                        width: "45",
                                        color: "white",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { size: "12" } }, [
                                    _vm._v("fas fa-home px-2 py-2"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Home")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "mt-3 px-2 py-2 mx-auto shadow shadow-sm border-radius-lg d-block",
                                      attrs: {
                                        "min-width": "45",
                                        ripple: false,
                                        height: "45",
                                        width: "45",
                                        color: "white",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { size: "12" } }, [
                                    _vm._v("fas fa-search px-2 py-2"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Search")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "mt-3 px-2 py-2 mx-auto shadow shadow-sm border-radius-lg d-block",
                                      attrs: {
                                        "min-width": "45",
                                        ripple: false,
                                        height: "45",
                                        width: "45",
                                        color: "white",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { size: "12" } }, [
                                    _vm._v("fas fa-ellipsis-h px-2 py-2"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Minimize")])]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "ms-0 text-center",
                  attrs: { lg: "8", md: "11" },
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "me-auto" }, [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "display-h-1 font-weight-bold mt-n6 mb-0 text-typo",
                        },
                        [_vm._v(" 28°C ")]
                      ),
                      _c(
                        "h6",
                        {
                          staticClass:
                            "text-uppercase text-h6 font-weight-bold text-typo text-left mb-0 ms-1",
                        },
                        [_vm._v(" Cloudy ")]
                      ),
                    ]),
                    _c("div", { staticClass: "ms-auto text-end" }, [
                      _c("img", {
                        staticClass: "w-50 mt-lg-n4",
                        attrs: {
                          src: require("@/assets/img/small-logos/icon-sun-cloud.png"),
                          alt: "image sun",
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "move-on-hover border-radius-xl overflow-hidden",
                            },
                            [
                              _c("div", { staticClass: "px-6 py-6" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold mb-0 me-3",
                                    },
                                    [_vm._v(" 08:00 ")]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold d-block text-left mb-0",
                                    },
                                    [
                                      _vm._v(" Synk up with Mark "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "text-secondary font-weight-normal d-block",
                                        },
                                        [_vm._v("Hangouts")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("hr", {
                                  staticClass: "horizontal dark my-4",
                                }),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold mb-0 me-3",
                                    },
                                    [_vm._v(" 09:30 ")]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold d-block text-left mb-0",
                                    },
                                    [
                                      _vm._v(" Gym "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "text-secondary font-weight-normal d-block",
                                        },
                                        [_vm._v("World Class")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("hr", {
                                  staticClass: "horizontal dark my-4",
                                }),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold mb-0 me-3",
                                    },
                                    [_vm._v(" 11:00 ")]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold d-block text-left mb-0",
                                    },
                                    [
                                      _vm._v(" Design Review "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "text-secondary font-weight-normal d-block",
                                        },
                                        [_vm._v("Zoom")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "w-100 text-primary",
                                                  attrs: {
                                                    "min-width": "45",
                                                    elevation: "0",
                                                    ripple: false,
                                                    height: "35",
                                                    width: "45",
                                                    color: "#f8f9fa",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [_vm._v("fas fa-chevron-down")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Show More")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "bg-gradient-default move-on-hover border-radius-xl overflow-hidden",
                            },
                            [
                              _c("div", { staticClass: "px-6 py-6" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "mb-0 text-h5 font-weight-bold text-white",
                                    },
                                    [_vm._v(" To Do ")]
                                  ),
                                  _c("div", { staticClass: "ms-auto" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "text-white text-h1 font-weight-bold text-end mb-0 mt-n2",
                                      },
                                      [_vm._v(" 7 ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-sm mb-0 text-white",
                                      },
                                      [_vm._v("items")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "text-white text-left mb-0" },
                                  [_vm._v("Shopping")]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-white text-left mb-0" },
                                  [_vm._v("Meeting")]
                                ),
                              ]),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "w-100 text-white",
                                                  attrs: {
                                                    "min-width": "45",
                                                    elevation: "0",
                                                    ripple: false,
                                                    height: "35",
                                                    width: "45",
                                                    color: "transparent",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [_vm._v("fas fa-chevron-down")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Show More")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "move-on-hover border-radius-xl mt-6",
                            },
                            [
                              _c("div", { staticClass: "px-6 py-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c("p", { staticClass: "mb-0 text-body" }, [
                                      _vm._v("Emails (21)"),
                                    ]),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "text-capitalize ms-auto px-0 py-0",
                                                        attrs: {
                                                          text: "",
                                                          large: "",
                                                          color: "#0A3542",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("Check")]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Check your emails"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "bg-gradient-primary move-on-hover border-radius-xl",
                              style: `background-image: url(${require("../../assets/img/curved-images/curved1.jpg")}); background-size: cover;`,
                            },
                            [
                              _c("div", { staticClass: "px-6 py-6" }, [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-white text-h5 font-weight-bold text-left mb-0",
                                  },
                                  [_vm._v(" Some Kind Of Blues ")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-white text-sm text-left",
                                  },
                                  [_vm._v("Deftones")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-10 text-left" },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          outlined: "",
                                                          rounded: "",
                                                          color: "#fff",
                                                          width: "45px",
                                                          height: "45px",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "12" } },
                                                      [
                                                        _vm._v(
                                                          "fas fa-backward"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [_c("span", [_vm._v("Prev")])]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mx-2",
                                                        attrs: {
                                                          icon: "",
                                                          outlined: "",
                                                          rounded: "",
                                                          color: "#fff",
                                                          width: "45px",
                                                          height: "45px",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "12" } },
                                                      [_vm._v("fas fa-play")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [_c("span", [_vm._v("Pause")])]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          outlined: "",
                                                          rounded: "",
                                                          color: "#fff",
                                                          width: "45px",
                                                          height: "45px",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "12" } },
                                                      [_vm._v("fas fa-forward")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [_c("span", [_vm._v("Next")])]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "move-on-hover border-radius-xl mt-6",
                            },
                            [
                              _c("div", { staticClass: "px-6 py-6" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "p",
                                    { staticClass: "my-auto text-body" },
                                    [_vm._v("Messages")]
                                  ),
                                  _c("div", { staticClass: "ms-auto" }, [
                                    _c(
                                      "span",
                                      { staticClass: "avatar-group d-flex" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              color: "#212529",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-avatar",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: "36",
                                                            },
                                                          },
                                                          "v-avatar",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/img/team-1.jpg"),
                                                            alt: "Avatar",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("2 New Messages"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              color: "#212529",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-avatar",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: "36",
                                                            },
                                                          },
                                                          "v-avatar",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/img/team-2.jpg"),
                                                            alt: "Avatar",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("1 New Message"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              color: "#212529",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-avatar",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: "36",
                                                            },
                                                          },
                                                          "v-avatar",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/img/team-3.jpg"),
                                                            alt: "Avatar",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("13 New Messages"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              color: "#212529",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-avatar",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: "36",
                                                            },
                                                          },
                                                          "v-avatar",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/img/team-4.jpg"),
                                                            alt: "Avatar",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("7 New Messages"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }